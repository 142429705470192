import React, {useState} from 'react'
import SectionTitle from "../../../../components/core/SectionTitle";
import Widget from "../../../../components/core/Widget";
import {Form} from "react-final-form";
import EventField from "../../../../components/FinalForm/ApiFields/EventField";
import Button from "../../../../components/Button/Button";
import {apiGetSuperPartnerSummary, apiGetSuperPartnerSummaryDetailed} from "./action";
import {useSelector} from "react-redux";
import Export from "./Export";
import {FiFile} from "react-icons/fi";
import ExportDetailed from "./ExportDetailed";
import {useTranslation} from "react-i18next";

const SuperPartnerSummary = () => {

    let [loading, setLoading] = useState(false)
    let [summary, setSummary] = useState(null)
    let [summaryDetailed, setSummaryDetailed] = useState(null)

    const {selectedPartner} = useSelector(state => state.user)
    const {t} = useTranslation();


    const onSubmit = (values) => {
        setSummary(null)
        setSummaryDetailed(null)
        if (values.type === 1) {
            getDetailedSummary(values)
        } else {
            getSummary(values)
        }
    }

    const getSummary = (values) => {
        setLoading(true)

        apiGetSuperPartnerSummary(selectedPartner.id, values.eventIds, (data) => {
            setSummary(data)
            setLoading(false)
        }, err => setLoading(false))
    }

    const getDetailedSummary = (values) => {
        setLoading(true)

        apiGetSuperPartnerSummaryDetailed(selectedPartner.id, values.eventIds, (data) => {
            setSummaryDetailed(data)
            setLoading(false)
        }, err => setLoading(false))
    }

    const _renderContent = () => {
        return (
            <Form onSubmit={onSubmit} render={({handleSubmit, form}) => (
                <form onSubmit={handleSubmit}>
                    <div className={"flex flex-col space-y-2"}>
                        <EventField name={'eventIds'} isMulti={true} validate={value => !value}
                                    label={t('events.plural')}/>
                        <div className={"flex flex-row-reverse space-x-2 space-x-reverse"}>
                            <div>
                                <Button text={t('summary.detailExport')} icon={<FiFile/>} type={'submit'}
                                        loading={loading}
                                        onClick={() => {
                                            form.change('type', 1)
                                        }}/>
                            </div>
                            <div>
                                <Button text={t('summary.summaryExport')} icon={<FiFile/>} type={'submit'}
                                        loading={loading}
                                        onClick={() => {
                                            form.change('type', 2)
                                        }}/>
                            </div>
                        </div>
                    </div>

                </form>
            )}/>
        )
    }

    return (
        <>
            <SectionTitle title={t("summary.plural")} subtitle={t('summary.forEvents')}/>
            <Widget>
                {_renderContent()}
                {summary && <Export data={summary}/>}
                {summaryDetailed && <ExportDetailed data={summaryDetailed}/>}
            </Widget>
        </>
    )
}

export default SuperPartnerSummary;
