import React, {useEffect, useState} from 'react';
import SectionTitle from "../../../../../components/core/SectionTitle";
import Widget from "../../../../../components/core/Widget";
import {useParams} from "react-router-dom";
import {apiGetSuperPartnerOrder, apiSuperPartnerOrderAction} from "../../action";
import {useSelector} from "react-redux";
import ContentSpinner from "../../../../../components/core/Spinner/ContentSpinner";
import DateClassic from "../../../../../components/Date/DateClassic";
import Button from "../../../../../components/Button/Button";
import {FiBox, FiFile, FiInfo, FiSend} from "react-icons/fi";
import Table from "../../../../../components/Table/Table";
import FormModal from "../../../../../components/Modal/FormModal";
import TextField from "../../../../../components/FinalForm/TextField/TextField";
import * as EmailValidator from "email-validator";
import {toast} from "react-hot-toast";
import {switchError} from "../../../../../api/api";
import FileSaver from 'file-saver'
import SelectField from "../../../../../components/FinalForm/SelectField/SelectField";
import {Form} from "react-final-form";
import Alert from "../../../../../components/core/Alert";
import {useTranslation} from "react-i18next";

const OrderDetail = () => {

    let [data, setData] = useState(null)
    let [pdfLoading, setLoadingPdf] = useState(false)
    let [emailLoading, setEmailLoading] = useState(false)
    let [sendModal, setSendModal] = useState(false)
    let [storno, setStorno] = useState(false)

    const {selectedPartner} = useSelector(state => state.user)
    const {orderId} = useParams();
    const {t} = useTranslation()


    useEffect(() => apiGetSuperPartnerOrder(selectedPartner.id, orderId, (data) => setData(data), err => {
        if (err.status === 400) {
            if (err.type === 'ORDER_STORNO') {
                setStorno(true)
            }
        }
    }), [])

    const onPdf = (params = {type: 'pdf'}) => {
        setLoadingPdf(true)
        apiSuperPartnerOrderAction(selectedPartner.id, orderId, params, (data) => {
            if (params.type === 'pdf') {
                let name = new Date().getTime().toString()
                var blob = new Blob([data], {type: "application/pdf"});
                FileSaver.saveAs(blob, name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(" ", "_") + '.pdf')
            } else {
                var blob = new Blob([data], {type: "application/zip"});
                if (params.offset !== undefined) {
                    FileSaver.saveAs(blob, `${orderId}_${params.offset + 1}.zip`)
                } else {
                    FileSaver.saveAs(blob, `${orderId}.zip`)
                }

            }

            setLoadingPdf(false)
        }, err => {
            setLoadingPdf(false)
        }, {responseType: 'arraybuffer'})
    }

    const onEmailSend = (values) => {
        setEmailLoading(true)
        apiSuperPartnerOrderAction(selectedPartner.id, orderId, {type: 'email', email: values.email}, (data) => {
            setSendModal(false)
            setEmailLoading(false)
            toast.success(t('basics.mailSent'))
        }, err => {
            switchError(err, [], true)
        })
    }

    const getOptions = () => {
        let array = [];
        for (let i = 0; i < data.tickets.length / 100; i++) {
            array.push({
                id: i,
                name: t("basics.fileWithIndex", {index: i + 1})
            })
        }

        return array;
    }

    const _renderButtons = () => {
        if (data) {
            if (data.tickets.length > 100) return (
                <Form onSubmit={values => onPdf({type: 'zip', offset: values.offset})} render={({handleSubmit}) => (
                    <div>
                        <div>
                            <SelectField options={getOptions()} label={t('basics.zipFile')} name={'offset'}
                                         isMulti={false}
                                         validate={true}/>
                        </div>
                        <div>
                            <Button onClick={handleSubmit} loading={pdfLoading} rounded={true}
                                    text={t('orders.downloadZip')}
                                    icon={<FiFile/>}/>
                        </div>

                    </div>
                )}/>
            )
        }
    }

    const _renderContent = () => {
        if (storno) return <h4 className='text-center text-enigoo'>{t("orders.cancelledOnEnigoo")}</h4>
        if (data) return (
            <div className='p-4'>
                <div className='flex flex-row mb-4'>
                    <div className='flex flex-col'>
                        <span className='text-enigoo text-2xl uppercase font-bold'>{t("orders.single")}</span>
                        <span className='text-blue-grey-800'>#{data.order.id}</span>
                    </div>
                </div>
                <div className='flex lg:flex-row flex-col lg:items-center justify-between mb-8'>
                    {data.order.user &&
                        <div className='flex flex-col'>
                            <span className='text-enigoo font-bold'>{t("basics.createdBy")}</span>
                            <span className='text-blue-grey-800'>{data.order.user}</span>
                        </div>
                    }
                    <div className='flex flex-col'>
                        <span className='text-enigoo font-bold'>{t("basics.dateCreated")}</span>
                        <span className='text-blue-grey-800'><DateClassic dateIso={data.order.date}
                                                                          format={'LLL'}/></span>
                    </div>
                </div>
                <div className='flex flex-row w-full mb-8'>
                    <div className='flex flex-col'>
                        <div className='font-bold mb-2 text-enigoo'>Detail</div>
                        <div className={"grid lg:w-1/3 lg:grid-cols-2 grid-cols-1 lg:gap-0 gap-2"}>

                            <div
                                className='text-blue-grey-400 text-xs font-medium uppercase tracking-wider'>{t("events.single")}</div>
                            <div className='whitespace-no-wrap'>{data.event.name} (<DateClassic
                                dateIso={data.event.startTime} format={'LLL'}/>)
                            </div>
                            <div
                                className='text-blue-grey-400 text-xs font-medium uppercase tracking-wider'>{t("customers.single")}</div>
                            <div className='whitespace-no-wrap'>{data.order.customer ? data.order.customer : "-"}</div>

                            <div
                                className='text-blue-grey-400 text-xs font-medium uppercase tracking-wider'>{t("subjects.single")}</div>
                            <div className='whitespace-no-wrap'>{data.order.subject.name}</div>
                            <div
                                className='text-blue-grey-400 text-xs font-medium uppercase tracking-wider'>{t("partners.single")}</div>
                            <div
                                className='whitespace-no-wrap'>{data.order.partner ? data.order.partner.name : "-"}</div>
                        </div>
                    </div>
                </div>
                <div>
                    {data.order.note &&
                        <Alert size={'sm'} raised={true} borderLeft={true} withoutClose={true}
                               color={'border-purple-900 text-xs mb-4'}
                               children={<div><FiInfo style={{display: 'inline-block'}}/> <span
                                   style={{verticalAlign: 'middle'}}>{data.order.note}</span>
                               </div>}/>
                    }
                </div>
                {!data.order?.noPdf &&
                    <>
                        {_renderButtons()}
                        <div className='ml-auto lg:w-1/3 lg:place-content-end grid lg:grid-cols-3 grid-cols-1 gap-2'>
                            {data && data.tickets.length <= 100 &&
                                <>

                                    <Button onClick={() => onPdf({type: 'zip'})} loading={pdfLoading} rounded={true}
                                            text={t("orders.downloadZip")}
                                            icon={<FiBox/>}/>
                                    <Button onClick={() => onPdf()} loading={pdfLoading} rounded={true}
                                            text={t("orders.downloadPdf")}
                                            icon={<FiFile/>}/>
                                    <Button onClick={() => setSendModal(true)} rounded={true}
                                            loading={emailLoading}
                                            text={t('orders.sendToMail')} icon={<FiSend/>}/>
                                </>
                            }
                        </div>
                    </>
                }
            </div>
        )
    }

    return (
        <>
            <SectionTitle title={t('orders.single')} subtitle={t('orders.detail')}/>
            <Widget>
                <ContentSpinner isSpinning={!data && !storno}>
                    {_renderContent()}
                </ContentSpinner>
            </Widget>
            {data &&
                <Widget title={t('tickets.plural')}>
                    <Table data={data.tickets} columns={[
                        {
                            Header: t('tickets.enigooId'),
                            accessor: 'id'
                        },
                        {
                            Header: t('tickets.sector'),
                            accessor: 'sector'
                        },
                        {
                            Header: t('tickets.row'),
                            accessor: 'row'
                        },
                        {
                            Header: t('tickets.place'),
                            accessor: 'place'
                        }]}/>
                </Widget>
            }
            {data &&
                <FormModal onSubmit={onEmailSend} onClose={() => setSendModal(false)}
                           initialValues={{email: data.order.customer}} visible={sendModal} loading={emailLoading}
                           title={t('orders.sendToMail')} body={
                    <TextField inputType={'email'} label={t('basics.email')} name={'email'} validate={value => {
                        if (!value) return true;
                        if (!EmailValidator.validate(value)) return t("errors.fieldMustBeEmail")
                        return false;
                    }}/>
                }/>
            }
        </>
    )
}

export default OrderDetail;
