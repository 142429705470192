import React from "react";
import {useTable} from "react-table";

const Table = ({columns, data, emptyText}) => {

    const tableInstance = useTable({columns, data})

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = tableInstance


    if (rows.length === 0) return <div className='text-center'><h5>{emptyText}</h5></div>

    return (
        <div style={{
            scrollbarColor: "#62438e #835eb9",
            scrollbarWidth: "thin"
        }} className={"overflow-x-auto sm:scrollbar md:scrollbar"}>
            <table className='table table-lg text-sm overflow-x-auto' {...getTableProps()}>
                <thead>
                {// Loop over the header rows
                    headerGroups.map(headerGroup => (
                        // Apply the header row props
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {// Loop over the headers in each row
                                headerGroup.headers.map((column, columnKey) => (
                                    // Apply the header cell props
                                    <th {...column.getHeaderProps()} key={"column_" + columnKey}>
                                        {// Render the header
                                            column.render('Header')}
                                    </th>
                                ))}
                        </tr>
                    ))}
                </thead>
                {/* Apply the table body props */}
                <tbody {...getTableBodyProps()}>
                {// Loop over the table rows
                    rows.map(row => {
                        // Prepare the row for display
                        prepareRow(row)
                        return (
                            // Apply the row props
                            <tr {...row.getRowProps()}>
                                {// Loop over the rows cells
                                    row.cells.map((cell, cellKey) => {
                                        // Apply the cell props
                                        return (
                                            <td {...cell.getCellProps()} key={'cell_' + cellKey}>
                                                {// Render the cell contents
                                                    cell.render('Cell')}
                                            </td>
                                        )
                                    })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default Table
