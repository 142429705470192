import React, {useEffect, useState} from 'react'
import Item from './Item'
import '../../../css/components/left-sidebar-1.css'
import {chooser, menu, partnerMenu, superPartnerMenu, switcher} from "./Menu";
import {useDispatch, useSelector} from "react-redux";
import {TYPE_PARTNER, TYPE_SUPER_PARTNER} from "../../../reducers/admin/user";
import {useTranslation} from "react-i18next";
import {RiMenuFoldFill} from "react-icons/ri";
import {SET_COLLAPSED} from "../../../reducers/admin/config";

const Sidebar = () => {

    let [navigation, setNavigation] = useState(null)

    const {isCollapsed} = useSelector(state => state.config)
    const {user, selectedPartner, hasMultiplePartners} = useSelector(state => state.user)
    const {t,i18n} = useTranslation()

    const dispatch = useDispatch();

    useEffect(() => {
        setPartner()
    }, [selectedPartner,i18n.language])

    const setPartner = () => {
        let _menu = [...menu]
        if (selectedPartner) {
            _menu[0].title = selectedPartner.name
            if (selectedPartner.type === TYPE_SUPER_PARTNER) {
                _menu[0].items = superPartnerMenu
            } else if(selectedPartner.type === TYPE_PARTNER){
                _menu[0].items = partnerMenu
            }
            if (hasMultiplePartners) {
                let find = _menu[0].items.find(item => item.url === 'switch-partner')
                if (!find) {
                    _menu[0].items.push(switcher)
                }
            }
        } else {
            let find = _menu[0].items.find(item => item.url === 'switch-partner')
            if (!find) {
                _menu[0].items.push(chooser)
            }


        }
        setNavigation([..._menu])
    }

    if (!navigation) return null

    return (
        <div className={`left-sidebar left-sidebar-1 
        md:${!isCollapsed ? "overflow-y-auto" : ""} lg:${!isCollapsed ? "overflow-y-auto" : ""} xl:${!isCollapsed ? "overflow-y-auto" : ""}`}>
            {isCollapsed ?
                <button
                    onClick={() => dispatch({type: SET_COLLAPSED, payload: !isCollapsed})}
                    className="lg:hidden ml-auto mt-4 mr-4 block">
                    <RiMenuFoldFill size={20}/>
                </button> : <></>}
            {navigation.map((menu, i) => (

                <div key={i}>
                    {!user.isAdmin && menu.isAdmin === true ? null :
                        <div className="left-sidebar-title">
                            <span>{t(menu.title)}</span>
                        </div>
                    }
                    <ul>
                        {menu.items.map((l0, a) => (
                            <li key={a} className="l0">
                                <Item {...l0} title={t(l0.title)}/>
                                <ul>
                                    {l0.items.map((l1, b) => (
                                        <li key={b} className="l1">
                                            <Item {...l1} title={t(l1.title)}/>
                                            <ul>
                                                {l1.items.map((l2, c) => (
                                                    <li key={c} className="l2">
                                                        <Item {...l2} title={t(l2.title)}/>
                                                        <ul>
                                                            {l2.items.map((l3, d) => (
                                                                <li key={d} className="l3">
                                                                    <Item {...l3} title={t(l3.title)}/>
                                                                    <ul>
                                                                        {l3.items.map((l4, e) => (
                                                                            <li key={e} className="l4">
                                                                                <Item {...l4} title={t(l4.title)}/>
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>
                                    ))}
                                </ul>
                            </li>
                        ))}
                    </ul>
                </div>
            ))}
        </div>
    )
}

export default Sidebar
